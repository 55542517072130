import { useEffect, useState } from 'react';

import List from '../../../../../../components/list/list';
import Input from '../../../../../../components/inputs/inputs-text';
import InputTextArea from '../../../../../../components/inputs/inputs-text-area';
import InputsFileSelect from '../../../../../../components/inputs/inputs-file-select';
import Checkbox from '../../../../../../components/checkbox';
import Dropdown from '../../../../../../components/inputs/inputs-dropdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import Row from '../../../../../../utils/row';

import QuestionType from "../../../../../../components/question/question-type-container";
import QuestionSection from '../../../../../../components/question/question-type-section';

import LabelContainer from '../../../../../../components/label-container';
import ContainerGrid from '../../../../../../containers/container-grid';

import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

import { AddButton, CancelButton } from '../../../../../../components/buttons';

import 'react-tooltip/dist/react-tooltip.css'

import { Tooltip } from 'react-tooltip'
import alertManager from '../../../../../../managers/alert/alert-manager';

import { Editor } from '@tinymce/tinymce-react'

export default function QuestionTypeFillBlank({
    question,
    questions,
    index,
    onQuestionChange,
    getMappedQuestions,
    getNextQuestion,
}) {
    const onConfigChange = (e) => {
        const { name, value } = e.target;

        const config = { ...question.config }

        config[name] = value;

        onQuestionChange({
            target: {
                name: 'config',
                value: config
            }
        });
    }

    const addType = (type) => {
        const body = question?.config?.fields !== null && question?.config?.fields !== undefined ? [ ...question?.config?.fields ] : [];

        let data = {};

        if (type === 'dropdown') {
            data = {
                type,
                options: ''
            }
        }

        if (type === 'input') {
            data = {
                type,
                value: '',
            }
        }

        body.push(data);

        onConfigChange({
            target: {
                name: 'fields',
                value: body,
            }
        })
    }

    const onTypeChange = (e, index) => {
        const body = question?.config?.fields !== null && question?.config?.fields !== undefined ? [ ...question?.config?.fields ] : [];

        const { name, value } = e.target;

        body[index][name] = value;

        onConfigChange({
            target: {
                name: 'fields',
                value: body,
            }
        })
    }

    const onCorrectAnswersChange = (value, index) => {
        const body = question?.correct_answers !== undefined ? [ ...question?.correct_answers ] : []

        body[index] = value;

        onQuestionChange({
            target: {
                name: 'correct_answers',
                value: body,
            }
        })
    }

    const removeType = (index) => {
        let body = question?.config?.fields !== null && question?.config?.fields !== undefined ? [ ...question?.config?.fields ] : [];

        let correct_answers = question?.correct_answers || [];
      
        body.splice(index, 1);
        correct_answers.splice(index, 1);

        onQuestionChange({
            target: {
                name: 'correct_answers',
                value: correct_answers,
            }
        })

        onConfigChange({
            target: {
                name: 'fields',
                value: body,
            }
        })
    }

    const getDropdown = (obj, index) => {        
        return (
            <Row fill={true}>
                <Input  
                    value={obj.options}
                    onChange={(e) => {
                        onTypeChange({
                            target: {
                                name: 'options',
                                value: e.target.value.split(','),
                            }
                        }, index)
                    }}
                />
                <Input  
                    value={question?.correct_answers?.[index]}
                    onChange={(e) => {
                        onCorrectAnswersChange(e.target.value.split(','), index)
                    }}
                />
            </Row>
        )
    }

    const getInput = (obj, index) => {
        return (
            <Input  
                value={obj.value}
                onChange={(e) => {
                    onTypeChange({
                        target: {
                            name: 'value',
                            value: e.target.value,
                        }
                    }, index)

                    onCorrectAnswersChange(e.target.value, index);
                }}
            />
        )
    }

    const fetchBlankType = (obj, index) => {
        switch(obj.type) {
            case 'input':
                return getInput(obj, index);
            case 'dropdown':
                return getDropdown(obj, index);
        }
    }

    return (
        <QuestionType title={question.title} type={question.type}>
            <QuestionSection left={
                <>
                    <Input label="Question Title" fill={true} name="title" value={question?.title} placeholder="Enter title here" onChange={onQuestionChange}/>

                    <LabelContainer label="Question Description">
                        <Editor
                            value={question?.description || ''}
                            onEditorChange={(value, editor) => {
                                onQuestionChange({
                                    target: {
                                        name: 'description',
                                        value: value,
                                    }
                                })
                            }}
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }
                            }
                        />
                    </LabelContainer>
                </>
            } right={
                <>
                    <Row wrap={true}>
                        <AddButton onClick={() => addType('dropdown')}>Dropdown</AddButton>
                        <AddButton onClick={() => addType('input')}>Input Field</AddButton>
                    </Row>
                </>
            }/>

            <List gap="20px">
                { question?.config?.fields?.map((obj, index) => {
                    return (
                        <LabelContainer key={index} label={
                            <p>
                                <CopyToClipboard text={`<[data-${index + 1}]>`} onCopy={() => {
                                    alertManager.message('success', 'FillBlank', 'Copied the placeholder');
                                }}>
                                    <span className={`copy-${index}`} style={{
                                        borderRadius: '3px',
                                        padding: '3px 5px',
                                        backgroundColor: '#9e9e9e',
                                        marginRight: '5px',
                                        color: 'white',
                                        cursor: 'pointer',
                                    }} dangerouslySetInnerHTML={{ __html: `<[data-${index + 1}]>` }}></span>
                                </CopyToClipboard>

                                <Tooltip 
                                    anchorSelect={`.copy-${index}`}
                                    content={`Click to copy`}
                                />
                                
                                (Click to Copy) {obj.type} field (type the word that the learner needs to write in - this should be case sensitive)
                            </p>
                            } grow={1}>
                            <Row fill={true}>
                                { fetchBlankType(obj, index) }
                                <CancelButton mobile={true} onClick={() => removeType(index)}/>
                            </Row>
                        </LabelContainer>
                    )
                })}
            </List>

            <InputTextArea 
                label="Statement"
                value={question?.body}
                onChange={(e) => {
                    onQuestionChange({
                        target: {
                            name: 'body',
                            value: e.target.value,
                        }
                    })
                }}
            />

            <Input 
                label="Correct Answer Response" 
                placeholder="Input Value"
                value={question?.config?.correct_answer_response}
                onChange={e => {
                    onConfigChange({
                        target: {
                            name: 'correct_answer_response',
                            value: e.target.value,
                        }
                    })
                }}
            />
            <Input 
                label="Incorrect Answer Response"
                placeholder="Input Value"
                value={question?.config?.incorrect_answer_response}
                onChange={e => {
                    onConfigChange({
                        target: {
                            name: 'incorrect_answer_response',
                            value: e.target.value,
                        }
                    })
                }}
            />
        </QuestionType>
    )
}
