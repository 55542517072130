import { Component, createRef } from 'react';

import SidePopup from '../../../../../components/side-popup';
import SidePopupSection from '../../../../../components/side-popup-section';
import { SaveButton } from '../../../../../components/buttons';

import LabelContainer from '../../../../../components/label-container';

import Row from '../../../../../utils/row';

import Input from '../../../../../components/inputs/inputs-text';
import Select from '../../../../../components/inputs/inputs-select';
import Dropdown from '../../../../../components/inputs/inputs-dropdown';
import TextArea from '../../../../../components/inputs/inputs-text-area';

import PopupContext from '../../../../../context/context-popup';

import { Editor } from '@tinymce/tinymce-react';

export default class CourseModuleSettings extends Component {
    static contextType = PopupContext;

    constructor(props) {
        super(props);

        this.ref = createRef(null);

        this.state = {
            module: this.props?.module,
        }
    }

    onChange(e) {
        const { name, value } = e.target;
        const module = { ...this.state.module }

        module[name] = value;

        this.setState({ module }, () => {
            console.log(this.state.module)
        });
    }

    save() {
        const course = { ...this.props.course };
        const course_version = course.latest_course_versions?.[0];
        const moduleIndex = course_version.modules.findIndex(m => m.id === this.props.module.id);

        course_version.modules[moduleIndex] = this.state.module;

        this.props.save(course).then(() => this.props.onSuccess());
    }

    head() {
        return(
            <div className="side-popup-head__text">
                <div className="side-popup-head__breadcrumb">
                    <p>Module : Dynamic</p>
                </div>

                <SaveButton onClick={this.save.bind(this)}>Save</SaveButton>
            </div>
        );
    }

    onConfigChange(e) {
        const { name, value } = e.target;
        const config = { ...this.state.module?.config } || {};

        config[name] = value;

        this.onChange({
            target: {
                name: 'config',
                value: config,
            }
        });
    }

    onPassLevel(e) {
        this.onConfigChange({
            target: {
                name: 'pass_level',
                value: Number(e.target.value)
            }
        })
    }

    render() {
        const questions = this.state.module?.sections?.reduce((partialSum, a) => partialSum + a.questions.length, 0);

        const contentVersion = this.props.course?.latest_course_versions?.[0] || [];

        let modules = contentVersion?.modules.map((m) => ({
            label: m.name,
            value: m.id,
        }))


        modules = modules.filter((_, index) => index > modules.findIndex(d => d.value === this.state.module.id));

        modules.push({
            label: 'Summary',
            value: null
        })

        return (
            <SidePopup type="grant-details-popup module-settings" head={this.head()} onClose={() => this.context.setPopup(null)} content={
                <>
                    <SidePopupSection title="General Details">
                        <Input name="name" label="Name of module [required]" placeholder="Name of Module" value={this.state.module?.name} onChange={this.onChange.bind(this)}/>
                        <LabelContainer label="Description of Module">
                            <Editor
                                onInit={(evt, editor) => this.ref.current = editor}
                                value={this.state.module?.description}
                                onEditorChange={(value, editor) => {
                                    this.onChange({
                                        target: {
                                            name: 'description',
                                            value: value
                                        }
                                    });
                                }}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }
                                }
                            />
                        </LabelContainer>
                        <LabelContainer label="Estimated time to complete this module [required]">
                            <Row>
                                <Input label="Input Hours"/>
                                <Input label="Input Min"/>
                            </Row>
                        </LabelContainer>
                    </SidePopupSection>

                    <SidePopupSection title="Assessment Settings">
                        <LabelContainer label="Pass Level">
                            <Input
                                type="number"
                                min={0}
                                max={questions}
                                value={this.state.module?.config?.pass_level}
                                onChange={this.onPassLevel.bind(this)}
                                label="Please select the number of correct answers required in order to pass this assessment module (the list will display the total quantity of questions inout in the module builder - please ensure you have entered all questions before confirming the pass level)."
                            />
                        </LabelContainer>

                        <div className="module-settings__item">
                            <LabelContainer label="Pass Message">
                                <TextArea
                                    placeholder="Enter message here"
                                    value={this.state.module?.config?.pass_message}
                                    name="pass_message"
                                    onChange={this.onConfigChange.bind(this)}
                                />
                                <Dropdown
                                    label="Next Module"
                                    value={modules.find(e => e.value === this.state.module?.config?.pass_module)}
                                    items={modules}
                                    reset={false}
                                    onChange={(e) => {
                                        this.onConfigChange({
                                            target: {
                                                name: 'pass_module',
                                                value: e.value
                                            }
                                        })
                                    }}
                                />
                            </LabelContainer>
                        </div>

                        <div className="module-settings__item">
                            <LabelContainer label="Fail Message">
                                <TextArea
                                    placeholder="Enter message here"
                                    value={this.state.module?.config?.fail_message}
                                    name="fail_message"
                                    onChange={this.onConfigChange.bind(this)}
                                />
                                <Row fill={true}>
                                    <Input
                                        label="CTA 1 Text"
                                        placeholder="Enter label"
                                        name="cta_one_text"
                                        onChange={this.onConfigChange.bind(this)}
                                        value={this.state.module?.config?.cta_one_text}
                                    />
                                    <Input
                                        label="CTA 1 Link"
                                        placeholder="Enter label"
                                        name="cta_one_link"
                                        onChange={this.onConfigChange.bind(this)}
                                        value={this.state.module?.config?.cta_one_link}
                                    />
                                </Row>
                                <Row fill={true}>
                                    <Input
                                        label="CTA 2 Text"
                                        placeholder="Enter label"
                                        name="cta_two_text"
                                        onChange={this.onConfigChange.bind(this)}
                                        value={this.state.module?.config?.cta_two_text}
                                    />
                                    <Input
                                        label="CTA 2 Link"
                                        placeholder="Enter label"
                                        name="cta_two_link"
                                        onChange={this.onConfigChange.bind(this)}
                                        value={this.state.module?.config?.cta_two_link}
                                    />
                                </Row>
                                <Dropdown
                                    label="Next Module"
                                    value={modules.find(e => e.value === this.state.module?.config?.fail_module)}
                                    items={modules}
                                    reset={false}
                                    onChange={(e) => {
                                        this.onConfigChange({
                                            target: {
                                                name: 'fail_module',
                                                value: e.value
                                            }
                                        })
                                    }}
                                />
                            </LabelContainer>
                        </div>
                    </SidePopupSection>
                </>
            }/>
        )
    }
}
